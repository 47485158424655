:root {
  --color-darkBlue: rgb(13, 0, 100);
  --color-lightBlue: rgb(197, 230, 255);
  --color-yellow: rgb(255, 202, 106);
  --color-yellowTransparent: rgba(255, 202, 106, 0.9);
  --color-yellowMoreTransparent: rgba(255, 202, 106, 0.6);
  --color-background: rgba(37, 46, 222, 0.85);
  --color-errorRed: rgb(255, 92, 92);
  --color-white: rgb(255, 255, 255);
}

body {
  font-family: "AvenirLTPro-Book", sans-serif;
  margin: 0;
  text-align: center;
  --bg-overlay: rgba(0, 0, 0, 0.4);
  background: linear-gradient(var(--bg-overlay), var(--bg-overlay)),
    url("../public/2019.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;

  box-sizing: border-box;

  font-size: 17px;
  line-height: 1.4;
  color: var(--color-lightBlue);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  min-height: 100vh;
}

h1 {
  font-size: 2.5rem;
  margin: 0;
}

button {
  font-size: 0.8rem;
  font-weight: 600;

  border: none;

  padding: 1rem 1.5rem;
  border-radius: 5px;
}

input[type="text"] {
  border: 0;
  border-radius: 5px;
  padding: 0.5rem 0.8rem;
}

a {
  color: var(--color-lightBlue);
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}
a:hover {
  color: var(--color-white);
}

.container {
  max-width: 100vh;
  background-color: var(--color-background);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 2rem;
}

.info {
  max-width: 50%;

  font-size: 1.3rem;
  text-align: left;
}

@media screen and (max-width: 730px) {
  .info {
    max-width: 100%;
    padding: 0 2rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;
}

.inputContainer {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 10rem;
}

.validationError {
  color: var(--color-errorRed);
  margin-bottom: 1rem;
}

.submitButton {
  background-color: var(--color-yellow);
  color: var(--color-darkBlue);
  width: 100%;
  max-width: 10rem;

  transition: all 0.2s ease-in-out;
}

.submitButton:hover {
  background-color: var(--color-yellowTransparent);
}

.submitButton:active {
  background-color: var(--color-yellowMoreTransparent);
}

.removeFromNewsletter {
  font-size: 0.8rem;
}
